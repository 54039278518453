@charset "utf-8";
/* CSS Document */


@media (max-width: 720px){
	.HEADER .MENU {
		display: none;
	}
	
	.blagodarnosti {
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 60px;
		padding: 45px 0 55px;
	}
	
	page {
		padding: 0 15px;
	}
	
	.HEADER {
		max-width:none;
		margin: 0;
		grid-template-areas:
			'menuIcon logo languages';
		grid-template-columns: 65px 1fr 65px;
		grid-template-rows: auto;
		grid-gap: 0px 15px;
		padding: 10px 0 5px;
	}
	
	.HEADER .menuIcon {
		display: block;
		align-self: center;
	}
	
	.HEADER .contact {
		display: none;
	}
	
	.HEADER .slogan {
		display: none;
	}
	
	.rezonSelect a.rsMain {
		padding: 0 25px 0 6px;
		background: url(../img/ico/menu-arrow-down.svg) right 7px center no-repeat;
	}
	
	.rezonSelect a.rsMain span {
		padding: 0 0 0 29px;
		height: 32px;
    	line-height: 32px;
	}
	
	.rezonSelect a.rsMain span type {
		display: none;
	}
	
	.HEADER .languages {
		align-self: center;
	}
	
	.rezonSelect .rsOptions {
		right: 0;
	}
	
	.HEADER .logo {
		text-align: center;
	}
	
	.HEADER .logo img {
		height: 60px;
		width: auto;
	}
	
	.photoBgrInfo {
		font-size: 18px;
		line-height: 30px;
		padding: 25px 10px;
	}
	
	.photoBgrInfo div {
		text-shadow: 2px 2px 11px #00000066;
	}
	
	.HEADER .menuIcon a.menuBtn {
		display: inline-block;
    line-height: 0;
    height: 44px;
    width: 44px;
    border-radius: 50px;
    background: url(../img/ico/menu.svg) center center no-repeat;
    background-size: auto 18px;
    border: 2px solid #e0e0e0;
    background-color: #FFF;
    transition: all .1s;
	}
	
	.klienti {
		padding: 30px 0;
	}
	
	.klienti .title {
		margin-bottom: 15px;
	}
	
	.klienti .loga {
		white-space: normal;
		animation: none;
	}
	
	.klienti .loga img {
		height: 30px;
		margin-top: 7px;
    	margin-bottom: 7px;
	}
	
	.klienti .loga img.duplicate {
		display: none;
	}
	
	.zapitvaneBanner {
		width: 100%;
		max-width: none;
		padding: 25px 0 35px;
	}
	
	.zapitvaneBanner .gird {
		grid-template-areas: 
			'text'
			'button';
		grid-template-columns: 1fr;
		grid-gap: 12px;
		text-align: center;
		line-height: 30px;
	}
	
	.zapitvaneBanner .gird .text {
		font-size: 17px;
		line-height: 26px;
	}
	
	.ourInfo {
		padding: 30px 0;
	}
	
	.ourInfo .grid {
		grid-template-areas: 
			'ico'
			'info';
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 20px;
		margin-bottom: 25px;
		text-align: center;
	}
	
	.ourInfo .grid .ico img {
		height: 96px;
		width: auto;
	}
	
	.ourInfo .grid .info {
		font-size: 16px;
		line-height: 26px;
	}
	
	.ourInfo .grid .info .title {
		font-size: 26px;
		line-height: 36px;
		margin-bottom: 0px;
	}
	
	.zapitvaneInPageForm {
		max-width: none;
		margin: 30px auto;
		box-shadow: 0 0 30px rgb(0 0 0 / 35%);
	}
	
	.zapitvaneInPageForm .grid {
		grid-template-areas:
			'name '
			'email'
			'langFrom'
			'langTo'
			'text'
			'files'
			'button';
		grid-template-columns: 1fr;
		grid-gap: 15px;
		padding: 20px;
	}
	
	.files {
		padding-top: 0px;
	}
	
	.button {
		padding-top: 10px;
	}
	
	
	/* ------------------------- */
	/* Странично меню за телефон */
	/* ------------------------- */
	
	.overlay {
		visibility: hidden;
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		background: rgb(255 255 255 / 65%);
		z-index: 999;
		opacity: 0;
		transition: all 0.5s ease-in-out;
	}

	.overlay.active {
		visibility: visible;
		opacity: 1;
	}
	
	sidebar {
		display: block;
		position: fixed;
		z-index: 9999999;
		top: 0;
		width: 100%;
		left: -100%;
		bottom: 0;
		background-color: #4558bb; /* #2f6ca1 #207caa */
    	color: #fff;
		padding: 25px;
		box-sizing: border-box;
		transition: all 0.3s;
	}
	
	sidebar.active {
		left: 0;
	}
	
	sidebar .items {
		margin-top: 20px;
		overflow-y: auto;
	}
	
	sidebar .items a {
		display: inline-block;
		color: #FFF;
		font-size: 18px;
		line-height: 42px;
	}
	
	sidebar .items a:hover,
	sidebar .items a.active {
		color: #FFFFFF99 !important;
	}
	
	sidebar .items a arrow {
		display: inline-block;
		width: 21px;
		height: 12px;
		background: url(../img/ico/menu-arrow-down-white.svg) 0 0 no-repeat;
		background-size: auto 6px;
		margin-left: 7px;
		vertical-align: -4px;
	}
	
	sidebar .submenu {
		display: none;
		padding-left: 20px;
		box-sizing: border-box;
	}
	
	sidebar .submenu.show {
		display: block;
	}
	
	sidebar .sMENU {
		height: 100%;
		display: grid;
		grid-template-rows: auto 1fr auto;
		grid-gap: 20px;
	}
	
	sidebar .sMENU .foot {
		text-align: center;
	}
	
	sidebar .sMENU .foot button {
		width: 100%;
	}
	
	sidebar .sMENU .foot a.email {
		display: inline-block;
		margin-top: 20px;
		font-size: 16px;
		font-weight: 300;
		color: #FFF;
		background: url("../img/ico/email-white.svg") 0 4px no-repeat;
		background-size: auto 12px;
		padding-left: 27px;
	}
	
	sidebar .sMENU .foot a.email:hover {
		color: #FFFFFF99;
	}
	
	sidebar .sMENU .head {
		display: grid;
		grid-template-columns: 1fr auto;
		grid-gap: 20px;
	}
	
	sidebar .sMENU .head .rezonSelect a.rsMain {
		background: url(../img/ico/menu-arrow-down-white.svg) right 8px center no-repeat;
		background-color: transparent;
		border: 1px solid #e0e0e047;
		padding: 0 26px 0 7px;
	}
	
	sidebar .sMENU .head .rezonSelect:hover .rsOptions {
		display: none;
		
	}
	
	sidebar .sMENU .head a.close {
		display: inline-block;
		color: #FFF !important;
		font-size: 32px;
		font-weight: 200;
		background: url("../img/ico/close-white.svg") 0 center no-repeat;
		background-size: auto 22px;
		padding-left: 36px;
	}
	
	/* Край на Странично меню за телефон */
	/* ------------------------- */
	/* ------------------------- */
	
	.hideFromDesktop {
		display: block;
	}
	
	page.inner {
		max-width:none;
		padding: 0 15px;
		box-sizing: border-box;
	}
	
	page.inner .pageGrid {
		grid-template-areas:
			'pageTitle'
			'hideFromDesktop'
			'main'
			'column'
			;
		grid-template-columns: 1fr;
		grid-template-rows: auto;
		grid-gap: 15px;
	}
	
	page.inner .pageGrid .pageTitle {
		padding-top: 25px;
		padding-bottom: 0px;
		font-size: 26px;
		line-height: 32px;
	}
	
	.zapitvaneInPageForm .files .addMore {
		grid-gap: 0px;
		font-size: 14px;
		margin-top: 15px;
	}
	
	.zapitvaneInColumn .info {
		text-align: center;
		font-size: 17px;
	}
	
	page.inner .pageGrid .column .sticky {
		box-shadow: 0 2px 10px rgb(0 0 0 / 10%);
	}
	
	.zapitvaneInColumn .info {
		padding: 15px 20px 20px;
	}
	
	page.inner .pageGrid .main zaglavie {
		font-size: 20px;
		margin-top: 30px;
	}
	
	.trohi items {
		padding: 10px 15px;
		font-size: 14px;
		line-height: 20px;
		font-weight: 500;
	}
	
	.btnHolder {
		margin-bottom: 17px;
	}	

	.zapitvaneBanner phone { 
		margin-bottom: 10px;
	}

	.zapitvaneBanner phone span { 
		display: block;
		margin-bottom: 10px;
	}

	.zapitvaneBanner phone num { 
		display: inline-block;
		font-size: 26px;
		line-height: 32px;
		margin-left: 0;
	}
}
