@charset "utf-8";
/* CSS Document */

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;1,200;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');

html {
  scroll-behavior: smooth;
}

body, select, textarea, input {
	font-family: 'Montserrat', sans-serif;
}

a, button {
	transition: .25s;
}

body {
	background-color: #f9f9f9;
	background-image: url(../img/body-bg.png);
    background-position: 50% 50%;
    background-repeat: no-repeat;
	
	color: #3b3d47;
	font-size: 16px;
	padding: 0;
	margin: 0;
	border: 0;
}

span.clever-link, a {
	display: inline-block;
	color: #356FC4;
	text-decoration: none;
	cursor: pointer;
}

span.clever-link:hover, a:hover {
	color: #356FC4;
}

::-webkit-input-placeholder {
   font-weight: 400;
}

:-moz-placeholder { /* Firefox 18- */
   font-weight: 400;
}

::-moz-placeholder {  /* Firefox 19+ */
   font-weight: 400;
}

:-ms-input-placeholder {  
   font-weight: 400;
}

button {
	background-color: #7cb973;
	color: #FFF;
	font-size: 15px;
	font-weight: 500;
	line-height: 46px;
	letter-spacing: 2px;
	text-transform: uppercase;
	border: none;
	border-radius: 4px;
	margin: 0;
	padding: 0 25px;
	cursor: pointer;
	position:relative;
}

button:hover {
	background-color: #2abb5d;
}

button.blue {
	background-color: #4f73a9;
}

button.blue:hover {
	background-color: #354CA8;
}

select {
	height: 46px;
	line-height: 38px;
	padding: 0 20px;
	background-color: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
	font-size: 16px;
}

input[type="text"]{
	width: 100%;
	height: 42px;
	padding: 0 10px;
	box-sizing: border-box;
	background-color: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 3px;
}

textarea {
	width: 100%;
	padding: 5px 10px;
	box-sizing: border-box;
	background: #FFF;
	border: 2px solid #e0e0e0;
	border-radius: 3px;
}


/* Margin Top (positive value) */
.MT0	{ margin-top: 0 !important }
.MT5	{ margin-top: 5px !important }
.MT10	{ margin-top: 10px !important }
.MT15	{ margin-top: 15px !important }
.MT20	{ margin-top: 20px !important }
.MT25	{ margin-top: 25px !important }
.MT30	{ margin-top: 30px !important }
.MT35	{ margin-top: 35px !important }
.MT40	{ margin-top: 40px !important }
.MT45	{ margin-top: 45px !important }
.MT50	{ margin-top: 50px !important }

/* Margin Top (negative value) */
.MT0	{ margin-top: 0 !important }
.MT5	{ margin-top: -5px !important }
.MT10	{ margin-top: -10px !important }
.MT15	{ margin-top: -15px !important }
.MT20	{ margin-top: -20px !important }
.MT25	{ margin-top: -25px !important }
.MT30	{ margin-top: -30px !important }
.MT35	{ margin-top: -35px !important }
.MT40	{ margin-top: -40px !important }
.MT45	{ margin-top: -45px !important }
.MT50	{ margin-top: -50px !important }

/* Margin Bottom (positive value) */
.MB0	{ margin-bottom: 0 !important }
.MB5	{ margin-bottom: 5px !important }
.MB10	{ margin-bottom: 10px !important }
.MB15	{ margin-bottom: 15px !important }
.MB20	{ margin-bottom: 20px !important }
.MB25	{ margin-bottom: 25px !important }
.MB30	{ margin-bottom: 30px !important }
.MB35	{ margin-bottom: 35px !important }
.MB40	{ margin-bottom: 40px !important }
.MB45	{ margin-bottom: 45px !important }
.MB50	{ margin-bottom: 50px !important }

/* Margin Bottom (negative value) */
.MB0	{ margin-bottom: 0 !important }
.MB5	{ margin-bottom: -5px !important }
.MB10	{ margin-bottom: -10px !important }
.MB15	{ margin-bottom: -15px !important }
.MB20	{ margin-bottom: -20px !important }
.MB25	{ margin-bottom: -25px !important }
.MB30	{ margin-bottom: -30px !important }
.MB35	{ margin-bottom: -35px !important }
.MB40	{ margin-bottom: -40px !important }
.MB45	{ margin-bottom: -45px !important }
.MB50	{ margin-bottom: -50px !important }

/* Padding Top */
.PT0	{ padding-top: 0 !important }
.PT5	{ padding-top: 5px !important }
.PT10	{ padding-top: 10px !important }
.PT15	{ padding-top: 15px !important }
.PT20	{ padding-top: 20px !important }
.PT25	{ padding-top: 25px !important }
.PT30	{ padding-top: 30px !important }
.PT35	{ padding-top: 35px !important }
.PT40	{ padding-top: 40px !important }
.PT45	{ padding-top: 45px !important }
.PT50	{ padding-top: 50px !important }

/* Padding Bottom */
.PT0	{ padding-bottom: 0 !important }
.PT5	{ padding-bottom: 5px !important }
.PT10	{ padding-bottom: 10px !important }
.PT15	{ padding-bottom: 15px !important }
.PT20	{ padding-bottom: 20px !important }
.PT25	{ padding-bottom: 25px !important }
.PT30	{ padding-bottom: 30px !important }
.PT35	{ padding-bottom: 35px !important }
.PT40	{ padding-bottom: 40px !important }
.PT45	{ padding-bottom: 45px !important }
.PT50	{ padding-bottom: 50px !important }



/* Custom Select */
.rezonSelect {
    position: relative;
	display: inline-block;
}

.rezonSelect:hover .rsOptions {
    display: block;
    animation: subMENU 0.25s cubic-bezier(.36,.07,.19,.97) both;
}

.rezonSelect a.rsMain {
	display: inline-block;
    padding: 0 33px 0 12px;
	box-sizing: border-box;
	background: url(../img/ico/menu-arrow-down.svg) right 10px center no-repeat;
    background-color: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 4px;
	color: #3b3d47;
    font-size: 16px;
}

.rezonSelect a.rsMain span {
	display: block;
    height: 42px;
    line-height: 42px;
    padding: 0 0 0 40px;
	box-sizing: border-box;
	background: 0 center no-repeat;
	background-size: auto 18px;
}

.rezonSelect a.rsMain.BG span {
	background-image: url("../img/flags/bg.svg")
}

.rezonSelect a.rsMain.EN span {
	background-image: url("../img/flags/en.svg")
}

.rezonSelect a.rsMain.RU span {
	background-image: url("../img/flags/ru.svg")
}


.rezonSelect .rsOptions {
	display: none;
	position: absolute;
	z-index: 1;
	min-width: 146px;
	margin-top: 0px;
	padding: 5px 15px 5px 12px;
	background-color: #fff;
	border: 2px solid #e0e0e0;
	border-top: none;
    border-radius: 4px;
	box-shadow: 0px 7px 6px #00000033;
}

.rezonSelect .rsOptions a {
	display: block;
    padding: 0 0 0 40px;
	box-sizing: border-box;
	background: 0 center no-repeat;
	background-size: auto 18px;
	color: #3b3d47;
    font-size: 16px;
	line-height: 32px;
	text-align: left;
}

.rezonSelect .rsOptions a:hover {
    color: #5689D3;
    transition: none;
}

.rezonSelect .rsOptions a.BG {
	background-image: url("../img/flags/bg.svg");
}

.rezonSelect .rsOptions a.EN {
	background-image: url("../img/flags/en.svg");
}

.rezonSelect .rsOptions a.RU {
	background-image: url("../img/flags/ru.svg");
}



page {
	display: block;
	width: 100%;
	max-width: 1200px;
	padding: 0 25px;
	margin: 0 auto;
	box-sizing: border-box;
}

page.whide {
	max-width: none;
}

page.whide.header {
	max-width: none;
	background: #FFF;
}

page.whide.noPadding {
	padding: 0;
}

page.whide.bgrWhite {
	background: #FFF;
}

page.whide.bgrBlue {
	background: #4558bb;
}

page.inner {
	max-width: 1200px;
	padding: 0 25px;
	box-sizing:content-box;
}

.HEADER {
	width: 100%;
	max-width: 1200px;
	margin: 0 auto;
	display: grid;
	grid-template-areas:
		'logo languages languages contact'
		'logo slogan slogan slogan'
		'MENU MENU MENU MENU';
	grid-template-columns:  300px auto 1fr auto;
	grid-template-rows: auto;
	grid-gap: 0px 15px;
	padding: 15px 0 25px;
}

.HEADER .menuIcon		{ grid-area: menuIcon; display: none; }
.HEADER .slogan			{ grid-area: slogan }
.HEADER .email			{ grid-area: email }
.HEADER .logo			{ grid-area: logo }
.HEADER .languages		{ grid-area: languages }
.HEADER .contact		{ grid-area: contact }
.HEADER .MENU			{ grid-area: MENU }

.HEADER .logo {
	line-height: 0;
}

.HEADER .logo img {
	width: 300px;
}

.HEADER .slogan {
	font-size: 36px;
	font-weight: 400;
	color: #426fc3;
	padding-top: 10px;
	text-align: right;
}

.HEADER .email {
	text-align: right;
	font-weight: 600;
	font-size: 16px;
	padding-top: 6px;
	box-sizing: border-box;
}

.HEADER .email a:hover {
	text-decoration: underline;
}

.HEADER .languages {
	align-self: flex-start;
	text-align: right;
}

.HEADER .contact {
	align-self: flex-start;
}

.MENU {
	padding-top: 25px;
}

.MENU .items {
	display: grid;
	grid-template-columns:  auto auto auto auto auto auto auto auto;
	grid-template-rows: auto;
	grid-gap: 20px;
}

.MENU .items a {
	color: inherit;
	text-transform: uppercase;
	font-size: 15px;
	font-weight: 600;
	text-align: center;
}

.MENU .items a:hover,
.MENU .items a.active {
	color: #5689D3;
	transition: none;
}


.MENU .items div {
	position: relative;
}

.MENU .items a arrow {
	display: inline-block;
	width: 21px;
	height: 12px;
	background: url("../img/ico/menu-arrow-down.svg") 0 0 no-repeat;
	margin-left: 7px;
	vertical-align: -3px;
}

.MENU .submenu {
	display: none;
	position: absolute !important;
	z-index: 1;
	left: -20px;
	background: #FFF;
	padding: 12px 20px;
	box-shadow: 0px 7px 6px #00000033;
}

.MENU .items div:hover .submenu {
	display: block;
	animation: subMENU 0.25s cubic-bezier(.36,.07,.19,.97) both;
}

.MENU .items div:hover a.main {
	color: #5689D3;
	transition: none;
}

@keyframes subMENU {
  0% {
    transform: translateY(3px);
  }
  
  100% {
    transform: translateY(0px);
  }
}

.MENU .submenu div {
	text-align: left;
	color: inherit;
    text-transform: uppercase;
	line-height: 38px;
	font-size: 14px;
	white-space: nowrap;
}

.MENU .submenu a {
	display: block;
	text-align: left;
	line-height: 38px;
	font-size: 14px;
	white-space: nowrap;
}

.photoBgrInfo {
	background: url("../img/flags-wallpaper.jpg") center center no-repeat;
	background-size: cover;
	color: #FFF;
	font-size: 32px;
	line-height: 52px;
	text-align: center;
	padding: 100px 0;
}

.photoBgrInfo div {
	width: 100%;
	max-width: 900px;
	margin: 0 auto;
	text-shadow: 2px 2px 22px #000000;
}

.klienti {
	width: 100%;
	/*max-width: 1200px;*/
	padding: 40px 0 40px;
	margin: 0 auto;
	text-align: center;
	overflow: hidden;
}

.klienti .title {
	font-size: 20px;
	font-weight: 300;
	color: #666;
	text-transform: uppercase;
	letter-spacing: 1px;
	margin-bottom: 20px;
}

.klienti .loga {
	white-space: nowrap;
	animation: loga-move 20s linear infinite;
    will-change: transform;
}

@keyframes loga-move {
    0% {
        transform: translate(0)
    }

    100% {
        transform: translateX(-1780px);
    }
}

.klienti img {
	height: 40px;
	margin-right: 20px;
	margin-top: 10px;
	margin-bottom: 10px;
}

.klienti img:last-of-type {
	margin-right: 0px;
}

.zapitvaneBanner {
	width: 100%;
	max-width: 1200px;
	padding: 50px 0;
	margin: 0 auto;
	color: #FFF;
}

.zapitvaneBanner .gird {
	display: grid;
	grid-template-areas: 
		'text button';
	grid-template-columns:  1fr auto;
	grid-template-rows: auto;
	grid-gap: 20px;
}

.zapitvaneBanner .gird .text	{ grid-area: text; }
.zapitvaneBanner .gird .button	{ grid-area: button; align-self: center; }

.zapitvaneBanner .gird .text { 
	font-size: 20px;
}

.zapitvaneBanner .gird .text strong { 
	color: #ffee00;
}

.zapitvaneBanner phone { 
	display: block;
	margin-bottom: 10px;
}

.zapitvaneBanner phone num { 
	color: white;
	display: inline-block;
	font-size: 26px;
	font-weight: 600;
	background: url("../img/ico/phone.svg") 0 center no-repeat;
	padding-left: 40px;
	margin-left: 12px;
}
.ourInfo {
	padding: 100px 0;
}

.ourInfo .grid {
	display: grid;
	grid-template-areas: 
		'ico info';
	grid-template-columns:  96px 1fr;
	grid-template-rows: auto;
	grid-gap: 35px;
	margin-bottom: 75px;
}

.ourInfo .grid:last-of-type {
	margin-bottom: 0;
}

.ourInfo .grid .ico		{ grid-area: ico; }
.ourInfo .grid .info	{ grid-area: info; }

.ourInfo .grid:last-of-type {
	margin-bottom: 0;
}

.ourInfo .grid .info {
	font-size: 20px;
	line-height: 28px;
	font-weight: 300;
}

.ourInfo .grid .info p {
	margin-bottom: 0;
}

.ourInfo .grid .info .title {
	font-size: 32px;
	font-weight: 600;
	margin-bottom: 30px;
	color: #49bb91;
}

.ourInfo .grid .info .title.blue {
	font-weight: 500;
	color: #426fc3;
}

.ourInfo .grid .info .bold {
	font-weight: 500;
	color: #49bb91;
}

.ourInfo .grid .info .blue {
	color: #426fc3;
}

.blagodarnosti {
	display: grid;
	grid-template-columns:  1fr 1fr 1fr;
	grid-gap: 20px;	
	width: 100%;
    max-width: 1200px;
    padding: 45px 0 55px;
    margin: 0 auto;
    text-align: center;
	line-height: 0;
}

.blagodarnosti .mnenie {
	font-size: 21px;
    line-height: 29px;
    font-style: italic;
    font-weight: 500;
	margin: 15px 0;
}

.blagodarnosti .persona {
	font-size: 15px;
	line-height: normal;
	margin-bottom: 13px;
}

.blagodarnosti .company {
	font-size: 13px;
	margin-bottom: 28px;
}

.blagodarnosti .logo img {
	/*width: 75px;*/
	max-height: 75px;
	max-width: 200px;
	
}


.zapitvaneInPageForm {
	width: 100%;
    max-width: 1020px;
    margin: 100px auto;	
	background: #FFF;
	border-radius: 6px;
	overflow: hidden;
    box-shadow: 0 0 30px rgb(0 0 0 / 35%);
}

.zapitvaneInPageForm .grid {
	display: grid;
	grid-template-areas:
		'name text'
		'email text'
		'langFrom files'
		'langTo files'
		'button files';
    grid-template-columns: 1fr 1fr;
    grid-gap: 15px 20px;
	padding: 20px;
}

.zapitvaneInPageForm .grid .name		{ grid-area: name; }
.zapitvaneInPageForm .grid .email		{ grid-area: email; }
.zapitvaneInPageForm .grid .langFrom	{ grid-area: langFrom; }
.zapitvaneInPageForm .grid .langTo		{ grid-area: langTo; }
.zapitvaneInPageForm .grid .text		{ grid-area: text; }
.zapitvaneInPageForm .grid .files		{ grid-area: files; }
.zapitvaneInPageForm .grid .button		{ grid-area: button; }

.zapitvaneInPageForm .grid input[type="text"],
.zapitvaneInPageForm .grid textarea {
	font-size: 17px;
}

.zapitvaneInPageForm .grid .name input[type="text"],
.zapitvaneInPageForm .grid .email input[type="text"]{
	font-weight: 700;
}

.zapitvaneInPageForm .title div {
	position: relative;
	color: #FFF;
	font-size: 22px;
	font-weight: 400;
	text-align: center;
	background-color: #80B378;
	padding: 20px 20px 30px;
}

.zapitvaneInPageForm .title div:after {
	background-color: #fff;
    border-radius: 100%;
    bottom: -10px;
    content: "";
    display: block;
    height: 20px;
    left: 0;
    position: absolute;
    width: 100%;
}

.zapitvaneInPageForm .text textarea {
	height: 101px;
}

.zapitvaneInPageForm .button button {
	width: 100%;
}

.zapitvaneInPageForm .AlertTitle {
	display: none;
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	color: #ff000080;
	padding: 10px 20px 0;
}

.zapitvaneInPageForm .AlertTitle.show {
	display: block;
	animation: alertTitleAN 0.7s ease both;
}

.zapitvaneInPageForm .AlertTitleOK {
	display: none;
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	color: #85b378;
	padding: 20px 20px 35px;
}

.zapitvaneInPageForm .AlertTitleOK span {
	display: block;
	margin-top: 15px;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
}

.zapitvaneInPageForm .AlertTitleOK.show {
	display: block;
	animation: alertTitleAN 0.7s ease both;
}

@keyframes alertTitleAN {
	0% {
		transform: translateY(10px);
		opacity: .5;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

.zapitvaneInPageForm .alert {
	display: none;
	background-color: #ff00000d;
    border-radius: 2px;
    color: #ff0000CC;
    font-size: 13px;
    font-size: .8666666667rem;
    line-height: 1.4;
    padding: 0.25em 10px;
}

.zapitvaneInPageForm .alert.show {
	display: block;
	animation: alertInfoAN 1.2s ease both;
}

@keyframes alertInfoAN {
	0% {
		transform: translateY(-30px);
		opacity: .1;
	}

	100% {
		transform: translateY(0px);
		opacity: 1;
	}
}

.zapitvaneInPageForm .redForm {
	position: relative;
    z-index: 1;
	animation: alertFormAN 1.2s ease both;
}

@keyframes alertFormAN {
	0% {
		border: 2px solid #ffffff00;
	}

	100% {
		border: 2px solid #ff000080;
	}
}

.zapitvaneInColumn {
	max-width: none;
	margin: 30px auto;
	box-shadow: 0 0 30px rgb(0 0 0 / 35%);
}

.zapitvaneInColumn:first-of-type {
	margin-top: 10px;
}

.zapitvaneInColumn .grid {
	grid-template-areas:
		'name '
		'email'
		'langFrom'
		'langTo'
		'text'
		'files'
		'button';
	grid-template-columns: 1fr;
	grid-gap: 15px;
	padding: 20px;
}

.zapitvaneInColumn .info {
	padding: 20px 20px 25px;
	line-height: 26px;
}

.zapitvaneInColumn .certificates {
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-gap: 20px;
	padding: 0 20px 20px;
	line-height: 0;
}

.zapitvaneInColumn .certificates a {
	display: block;
    line-height: 0;
    border: 1px solid #dbdbdb;
    padding: 4px;
    border-radius: 2px;
}

.zapitvaneInColumn .certificates a:hover {
	border-color: #4f73a9;
}

.zapitvaneInColumn .certificates a:hover img {
	opacity: .6;
}

.zapitvaneInColumn .certificates a img {
	width: 100%;
	transition: .25s;
}

.zapitvaneInColumn.gray .title div {
    background-color: #b0b0b0;
}

.zapitvaneInColumn.noShadow {
    box-shadow: none;
}

.fileInput {
	display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 10px;
	
	width: 100%;
    padding: 4px;
    box-sizing: border-box;
    background-color: #fff;
    border: 2px solid #e0e0e0;
    border-radius: 3px;
	
	
}

.fileInput label {
	background-color: #4f73a9;
	color: #FFF;
    font-size: 13px;
    font-weight: 500;
    line-height: 30px;
    text-transform: uppercase;
    border: none;
    border-radius: 4px;
    margin: 0;
    padding: 0 10px;
    cursor: pointer;
}

.fileInput label:hover {
    background-color: #354CA8;
}

.fileInput div {
    line-height: 30px;
	display: block;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	cursor: pointer;
}

.zapitvaneInPageForm .files .addMore {
	display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 10px;
	font-size: 15px;
	margin-top: 15px;
}

.zapitvaneInPageForm .files .addMore .limit{
	text-align: right;
}

.footerWrapper {
	margin-top: 30px;
	background: #FFF;
	border-top: 3px solid #EEE;
}

.FOOTER {
	text-align: center;
	padding-top: 40px;
	padding-bottom: 40px;
	font-size: 14px;
	line-height: 26px;
}

.FOOTER .logo img {
	height: 60px;
}

.FOOTER .copyright {
	margin-bottom: 2px;
}

.FOOTER .copyright strong {
	font-weight: 400;
}

.FOOTER a {
	display: inline-block;
	margin: 0 7px;
}

.FOOTER .preporachvame {
	margin-top: 15px;
}

.FOOTER .preporachvame strong {
	font-weight: 600;
	display: block;
	margin-bottom: 5px;
}

.FOOTER .preporachvame a {
	font-size: 16px;
}

.ofisi1stPage {
	display: grid;
	grid-template-columns:  1fr 1fr 1fr 1fr;
	grid-gap: 20px;	
	width: 100%;
    max-width: 1200px;
    padding: 115px 0 30px;
    margin: 0 auto;
    text-align: center;
}

.ofisi1stPage .ofis{
	position: relative;
	background: #FFF;
	box-shadow: 0px 4px 11px #00000033;
    border-radius: 8px;
    padding: 50px 15px 15px 15px;
	transition: .4s;
}

.ofisi1stPage .ofis:hover {
	background: #eff2f5;
	border-color: #eff2f5;
	color: #426fc3;
}

.ofisi1stPage .ofis:hover img.papagal {
	animation: papagalMove 0.25s cubic-bezier(.36,.07,.19,.97) both;
}

@keyframes papagalMove {
	0% {
		top: -1px;
	}

	50% {
		top: -21px;
	}

	100% {
		top: -1px;
	}
}

.ofisi1stPage .ofis a {
	position: absolute;
	z-index: 2;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
}

.ofisi1stPage .ofis img.papagal {
	position: absolute;
	top: -1px;
	left: 50%;
	-webkit-transform: translateY(-50%) translateX(-50%);
	-ms-transform: translateY(-50%) translateX(-50%);
	transform: translateY(-50%) translateX(-50%);
}

.ofisi1stPage .ofis .city {
	font-size: 22px;
	font-weight: 300;
	margin-bottom: 10px;
}

.ofisi1stPage .ofis .name {
	font-size: 16px;
	font-weight: 700;
	margin-bottom: 10px;
	color: #426fc3;
}

.ofisi1stPage .ofis .address {
	font-size: 15px;
	font-weight: 300;
	margin-bottom: 10px;
}

.ofisi1stPage .ofis .map {
	margin-top: 15px;
}

.ofisi1stPage .ofis .map img {
	width: 100%;
}

.ofisi1stPage .ofis .contact {
	color: #426fc3;
	font-weight: 400;
}

.ofisi1stPage .ofis .contact span {
	display: inline-block;
	padding-right: 13px;
	background: url("../img/ico/next-arrow-text-link.svg") right center no-repeat;
}

sidebar {
	display: none;
}

page.inner .pageGrid {
	display: grid;
	grid-template-areas:
		'pageTitle pageTitle'
		'main column';
	grid-template-columns:  1fr 430px;
	grid-template-rows: auto;
	grid-gap: 10px 60px;
}

page.inner .pageGrid .pageTitle	{ grid-area: pageTitle; }
page.inner .pageGrid .main		{ grid-area: main; }
page.inner .pageGrid .column	{ grid-area: column; }

page.inner .pageGrid .column .sticky {
	position: sticky;
	top: 20px;
}

page.inner .pageGrid .pageTitle {
	padding-top: 30px;
	padding-bottom: 20px;
	color: #426fc3;
	font-size: 42px;
	line-height: 56px;
	font-weight: 500;
	text-align: center;
}

.trohiWrapper {
	background: #e6e9ed;
}

.trohi {
	width: 100%;
    max-width: 1200px;
    margin: 0 auto;
}

.trohi items {
	display: block;
    padding: 10px 0px;
	font-size: 15px;
    line-height: 20px;
}

.trohi a {
	display: inline-block;
	transition: .25s;
}

.trohi a:hover {
	opacity: .75;
}

.trohi a.home {
	background: url("../img/papagal.svg") 0 center no-repeat;
	background-size: auto 20px;
	padding-left: 20px;
}

.trohi span {
	opacity: .75;
}

.trohi em {
	display: inline-block;
	font-style: normal;
	margin: 0 2px;
}

page.inner .pageGrid .main zaglavie {
	display: block;
	font-size: 22px;
	font-weight: 600;
	text-transform: uppercase;
	margin-top: 35px;
}

page.inner .pageGrid .main zaglavie:first-of-type {
	margin-top: 0px;
}

page.inner .pageGrid .main p {
	line-height: 28px;
}

page.inner .pageGrid .main a:hover {
	color: #8bb1e1;
}

page.inner .pageGrid .main strong {
	font-weight: 700;
}

page.inner .pageGrid .main ul.disc {
	padding: 0;
}

page.inner .pageGrid .main ul.disc li {
	list-style: none;
    padding: 0 0 0 35px;
    margin: 0 0 15px 0;
    background: url(../img/ico/cb.svg) 0 4px no-repeat;
	background-size: auto 18px;
	line-height: 28px;
}

page.inner .pageGrid .main ul.disc.linkIcon li {	
    padding: 0 0 0 30px;
    background: url("../img/ico/link.svg") 0 4px no-repeat;
	background-size: auto 18px;
}

page.inner .pageGrid .main ul.rowLinks {
	display: inline-block;
	width: 100%;
	padding: 0;
	margin-bottom: 5px;
}

page.inner .pageGrid .main ul.rowLinks li {
	float: left;
	list-style: none;
    padding: 0;
    margin: 0 5px 0 0;
	line-height: 26px;
	font-weight: 600;
}

.hideFromDesktop {
	display: none;
}

referencii {
	display: grid;
	grid-template-columns:  1fr 1fr 1fr;
	grid-template-rows: auto;
	grid-gap: 20px;
}

referencii a {
	display: block;
}

referencii img {
	width: 100%;
}

.btnHolder {
	text-align: center;
	margin-bottom: 25px;
}

.officePhotos {
	margin-top: 10px;
	text-align: center;
	line-height: 0;
}

.officePhotos a {
	display: inline-block;
	margin: 0 2px;
}

.fGallery a:hover {
	 animation: imgHover 1.50s cubic-bezier(.07,.13,.19,.97) both;
}

@keyframes imgHover {
	0% {
		opacity: .2;
	}

	100% {
		opacity: 1;
	}
}

.lineSeparator {
	height: 2px;
	background: #e6e9ed;
	margin: 30px 0;
}

.img100W {
	width: 100%;
}

.img100W.marginTop {
	margin-top: 15px;
}

.spinner {
	opacity: 1;
	position: absolute;
	right: 1rem;
	top: calc(50% - 8px);
	height: 16px;
}



.zapitvaneInPageForm .AlertTitleUP {
	/* display: none; */
	font-size: 22px;
	font-weight: 600;
	text-align: center;
	color: #b0b0b0;
	padding: 20px 20px 35px;
}

.zapitvaneInPageForm .AlertTitleUP span {
	display: block;
	margin-top: 15px;
	font-size: 18px;
	font-weight: 400;
	text-align: center;
}

.zapitvaneInPageForm .AlertTitleUP.show {
	/* display: block; */
	animation: alertTitleAN 0.7s ease both;
}

.htmx-indicator{
	/* opacity:0; */
	display:none;
	animation: alertTitleAN 0.7s ease both;
	/* transition: opacity 500ms ease-in; */
}
.htmx-request .htmx-indicator{
	/* opacity:1; */
	display:block;
}
.htmx-request.htmx-indicator{
	/* opacity:1; */
	display: block;
}
.htmx-request.zForms {
	display: none;
	animation: alertTitleAN 0.7s ease both;
}
